import { createClient } from "contentful"

export default class ContentClient {
  constructor() {
    this.client = createClient({
      // This is the space ID. A space is like a project folder in Contentful terms
      space: "0gh0czeqskb5",
      // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
      accessToken: "YsGQED9De2MpDGJ_9MS_t7bzaAsi9mdAu0kwyjxaQ2o",
    })
  }

  async getArticle(slug) {
    console.log(slug)
    const articles = await this.client.getEntries({
      content_type: "article",
      "fields.slug[in]": slug,
      include: 1,
      limit: 1,
      order: "fields.publishDate",
    })

    if (articles.total > 0) return articles.items[0]
    return null
  }
}
