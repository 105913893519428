import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Container,
  ThemeProvider,
  Breadcrumbs,
  Typography,
  Link,
} from "@mui/material"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import ScheduleIcon from "@mui/icons-material/Schedule"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import theme from "../../theme.js"
import ContentClient from "../../lib/contentClient"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import * as dayjs from "dayjs"

const contentClient = new ContentClient()

const Article = ({ params }) => {
  const [article, setArticle] = useState(null)
  useEffect(() => {
    contentClient.getArticle(params.id).then(article => setArticle(article))
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Seo
        title={"Vinkoll - " + (article ? article.fields.title : "Artikel")}
      />
      <Layout isHome={false}>
        <Container className="container">
          {article != null && (
            <>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{ mb: 3 }}
              >
                <Link underline="hover" key="1" color="inherit" href="/">
                  Vinkoll
                </Link>
                ,
                <Typography key="3" color="text.primary">
                  {article.fields.title}
                </Typography>
                ,
              </Breadcrumbs>
              <article className="content">
                {article.fields.featureImage ? (
                  <figure className="post-feature-image">
                    <img
                      src={article.fields.featureImage.fields.file.url}
                      alt={article.fields.title}
                    />
                  </figure>
                ) : null}
                <section className="post-full-content">
                  <Typography variant="h2">{article.fields.title}</Typography>
                  <Typography variant="subtitle1">
                    <ScheduleIcon fontSize="14" style={{ marginRight: 5 }} />
                    Publicerad:
                    {dayjs(article.fields.publishDate).format("YYYY-MM-DD")}
                  </Typography>
                  {/* The main post content */}
                  <section
                    style={{ marginTop: 25 }}
                    /*dangerouslySetInnerHTML={{
                        __html: documentToHtmlString(article.fields.body),
                      }}
                    /*/
                  >
                    {documentToReactComponents(article.fields.body)}
                  </section>
                </section>
              </article>
            </>
          )}
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

Article.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    id: PropTypes.string,
  }),
}

export default Article
